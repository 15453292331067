* {
  font-family: "Roboto", sans-serif;
}

a {
  text-decoration: none;
  font-weight: bold;
  padding: 20px;
  font-size: 40px;
  text-align: center;
  color: #293b8f;
}

.footerEmail {
  text-decoration: none;
  font-weight: bold;
  padding: 60px;
  font-size: 20px;
  text-align: center;
  color: #293b8f;
}

.wrapper {
  max-width: 800px;
  margin: auto;
  padding: 0 4%;
  display: flex;
  justify-content: center;
}

.col {
  display: flex;
  justify-content: center; /* align horizontal */
  align-items: center; /* align vertical */
  flex: 1;
}

.App-logo {
  padding: 10% 2%;
  display: flex;
  width: 100%;
  max-height: 600px;
  max-width: 600px;
}

.footer {
  width: 100%;
  max-height: 400px;
  padding-bottom: 8%;
}
